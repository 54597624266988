import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby-theme-material-ui';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0,
        margin: '4rem auto 3rem'
    },
    card: {
        position: 'relative',
    },
    text: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2rem',
        border: '1px solid #fff',
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.2)',
        transition: 'all 200ms ease-in-out',
        '& h4': {
            fontFamily: 'Dancing Script',
            fontWeight: 500
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.4)'
        }
    }
}));

const CategoriesCards = () => {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <Container maxWidth='lg' className={classes.container}>
                <Grid container spacing={4} justifyContent='center' alignItems='center'>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/news-people'>
                            <StaticImage src='../assets/images/news-people-small.jpg' alt='news' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>News/People</Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/coaching'>
                            <StaticImage src='../assets/images/coaching-small.jpg' alt='coaching' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>Coaching</Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/culture'>
                            <StaticImage src='../assets/images/culture-small.jpg' alt='culture' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>Culture</Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/family'>
                            <StaticImage src='../assets/images/family-small.jpg' alt='family' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>Family</Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/cooking'>
                            <StaticImage src='../assets/images/cooking-small.jpg' alt='cooking' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>Cooking</Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={6} className={classes.card}>
                        <Link to='/games'>
                            <StaticImage src='../assets/images/games-small.jpg' alt='games' placeholder='blurred' />
                            <div className={classes.text}>
                                <Typography variant='h4'>Games</Typography>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};

export default CategoriesCards;